<template>
  <div class="full-width">
    <div v-if="notes === null" style="margin: auto;">
      <q-spinner-dots size="60" class="dots" />
    </div>

    <q-card v-else class="bg-white notes full-width">
      <div v-if="!notes.length && !booking">
        <m-empty-state :style="{padding: '30px', margin: 'auto'}" icon="note">
          {{ $t('add_notes_here') }}
        </m-empty-state>
      </div>
      <q-list v-else>
        <q-item v-if="booking" :key="booking.reference">
          <q-item-section>
            <q-item-label class="note-heading">
              <strong>{{ booking.name }}</strong>
            </q-item-label>
            <q-item-label>
              <ul>
                <li><strong>{{ $t('booking.reference') }}:</strong> {{ booking.reference }}</li>
                <li><strong>{{ $t('booking.type') }}:</strong> {{ booking.type }}</li>
              </ul>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item
          v-for="note in notes.filter(n => n.parent_id == null)"
          :key="note.id"
          class="bg-white"
          multiline
        >
          <q-item-section avatar top>
            <m-image v-if="note.author && note.author.picture" :src="note.author.picture" width="38px" height="38px" class="avatar" />
          </q-item-section>
          <q-item-section>
            <q-item-label
              label
              class="note-heading"
            >
              <strong>{{ note.author.display_name }}</strong> <small> at {{ note.created_at | time }} on {{ note.created_at.date | date }}</small>
            </q-item-label>
            <q-item-label>
              <m-editor-viewer v-if="notes.filter(n => n.parent_id === note.id)" :content="note.body" style="padding-bottom: 0" />
              <m-editor-viewer v-else :content="note.body" />
            </q-item-label>
            <q-list v-if="notes.filter(n => n.parent_id === note.id)">
              <q-item
                v-for="childNote in notes.filter(n => n.parent_id === note.id)"
                :key="childNote.id"
                class="bg-white"
                multiline
              >
                <q-item-section side top aria-hidden="true">
                  &#9584;
                </q-item-section>
                <q-item-section>
                  <q-item-label>
                    <small>{{ childNote.body }}</small>
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>

    <note-dialog v-if="fab && allowCreate" :fab="true" :create="create" />
  </div>
</template>

<script type="text/javascript">
import date from 'utils/date-time'
import timezoneFilter from 'utils/timezoneFilter'
import noteDialog from './noteDialog'
import { MEmptyState, MImage, MEditorViewer } from 'components/'

export default {
  components: {
    noteDialog,
    MEmptyState,
    MImage,
    MEditorViewer
  },
  filters: {
    date (data) {
      return date.toShortDate(data)
    },
    time (data) {
      if (data.timezone === 'UTC') return timezoneFilter(data.date, 'Europe/London').split(', ')[1]
      return date.toCivilTime(data)
    }
  },
  props: {
    notes: Array,
    create: Function,
    allowCreate: {
      type: Boolean,
      default: false
    },
    fab: {
      type: Boolean,
      default: true
    },
    booking: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      dialogOpen: false,
      newNoteContent: '',
      done: false
    }
  },
  methods: {
    openDialog () {
      this.dialogOpen = !this.dialogOpen
    }
  }
}
</script>

<style lang="stylus">
.editor-dialog .modal-body
  padding 0
.editor-dialog .modal-buttons
  padding 15px
.editor-dialog .modal-scroll
  max-height 500px
.ql-toolbar.ql-snow
  border-top 0!important
  border-right 0!important
  border-left 0!important
  padding 1rem 2rem
.editor-dialog .ql-container
  border-right 0!important
  border-left 0!important
.editor-dialog .ql-editor
  min-height 150px
  max-height 200px
  overflow auto
.editor-wrapper
  min-height 200px
svg
  display block
  margin auto
.note-heading
  margin-top 10px
  margin-left 10px
</style>
